import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Form, Input, Select } from 'antd';
import { SectionMainWrapper } from '../../contact.style';
import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { updateUserInfo } from '../../../../actions';
import Cleave from 'cleave.js/react';
// import styles from "./reasons.module.css"
import './buyProcess.css';
//import { Radio } from 'antd';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 2,
    },
  },
};

const RefiMakingPaymentsComponent = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  description,
  currentUser,
  updateUserInfo,
  userToken,
  showLoader,
  hideLoader,
  loading,
}) => {
  const [form] = Form.useForm();

  const [makingPayments, setMakingPayments] = useState(null);
  const [liabilityHolder, setLiabilityHolder] = useState(null);
  const [monthlyPaymentAmount, setMonthlPaymentAmount] = useState(null);
  const [isPrimaryMortgage, setIsPrimaryMortgage] = useState(true);
  const [expectedRent, setExpectedRent] = useState(true);
  const [howHoldTitle, setHowHoldTitle] = useState(null);
  const [liabilityUnpaidBalanceAmount, setLiabilityUnpaidBalanceAmount] =
    useState(null);
  const [
    liabilityPaymentIncludesTaxesInsuranceIndicator,
    setLiabilityPaymentIncludesTaxesInsuranceIndicator,
  ] = useState(null);

  const handleMakingPayments = (e) => {
    console.log('own other real estate', e.target.value);
    setMakingPayments(e.target.value);
  };

  const handleLiablilityHolder = (e) => {
    console.log('own other real estate', e.target.value);
    setLiabilityHolder(e.target.value);
  };

  const handleUnpaidLiabilityBalanceAmount = (e) => {
    console.log('own other real estate', e.target.value);
    setLiabilityUnpaidBalanceAmount(e.target.value);
  };

  const handleMonthlyPaymentAmount = (e) => {
    console.log('own other real estate', e.target.value);
    setMonthlPaymentAmount(e.target.value);
  };

  const handleIsPrimaryMortgage = (e) => {
    console.log('own other real estate', e.target.value);
    setIsPrimaryMortgage(e.target.value);
  };

  const handleLiabilityPaymentIncludesTaxesInsuranceIndicator = (e) => {
    console.log('own other real estate', e.target.value);
    setLiabilityPaymentIncludesTaxesInsuranceIndicator(e.target.value);
  };

  const onFinish = async (value) => {
    console.log('in currentUser address', currentUser);

    //sell before purchase new property?

    console.log('in make payments');
    Object.assign(currentUser, {
      liabilities: {
        liabilityType: 'Mortgage',
        liabilityHolder: liabilityHolder,
        liabilityUnpaidBalanceAmount: liabilityUnpaidBalanceAmount,
        liabilityMonthlyPaymentAmount: monthlyPaymentAmount,
        liabilityPaymentIncludesTaxesInsuranceIndicator:
          liabilityPaymentIncludesTaxesInsuranceIndicator,
      },
    });
    console.log('in make payments');
    Object.assign(currentUser.subjectProperty.propertyDetail, {
      PropertyExistingLienAmount: parseInt(
        liabilityUnpaidBalanceAmount.replace(/,/g, '')
      ),
      PropertyEstimatedExistingLTV:
        (parseInt(liabilityUnpaidBalanceAmount.replace(/,/g, '')) /
          currentUser.subjectProperty.propertyDetail
            .PropertyEstimatedValueAmount) *
        100.0,
      PropertyRemainingEquity:
        currentUser.subjectProperty.propertyDetail
          .PropertyEstimatedValueAmount -
        parseInt(liabilityUnpaidBalanceAmount.replace(/,/g, '')),
    });
    Object.assign(currentUser.loan.termsOfLoan, {
      baseLoanAmount: parseInt(liabilityUnpaidBalanceAmount.replace(/,/g, '')),
      PropertyEstimatedExistingLTV:
        (parseInt(liabilityUnpaidBalanceAmount.replace(/,/g, '')) /
          currentUser.subjectProperty.propertyDetail
            .PropertyEstimatedValueAmount) *
        100.0,
      downPayment:
        currentUser.subjectProperty.propertyDetail
          .PropertyEstimatedValueAmount -
        parseInt(liabilityUnpaidBalanceAmount.replace(/,/g, '')),
    });

    console.log('currentUser after mortgage data', currentUser);

    updateUserInfo(currentUser, userToken);
    navigate('/doYouOwnOtherRealEstate/');
    //navigate('/buy-pages/assets/')
  };

  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container className="containerClassBuyProcess">
          <Box {...row}>
            <Form
              //{...formItemLayout}
              form={form}
              layout="vertical"
              name="register"
              onFinish={onFinish}
              scrollToFirstError
              style={{ width: '90%' }}
            >
              <Heading
                content={
                  'Please list the mortgage information for ' +
                  currentUser?.subjectProperty.address.addressLineText
                }
                fontWeight="bold"
                {...title}
              />

              <Text
                content={
                  "You'll need to refinance at least your primary mortgage. And you can refinance more than one at the same time."
                }
                //fontWeight="bold"
              />

              <Form.Item
                name="mortgageCompany"
                label="Company you write the check to"
                onChange={handleLiablilityHolder}
                //onChange={handleChangeFirstName}
                /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="balance"
                label="Balance"
                onChange={handleUnpaidLiabilityBalanceAmount}
                rules={[
                  {
                    required: true,
                    message: '*Required',
                  },
                ]}
              >
                <Cleave
                  className="ant-input"
                  placeholder="$"
                  options={{
                    numeral: true,
                    numeralIntegerScale: 7,
                    numeralPositiveOnly: true,
                    //prefix: '$',
                    signBeforePrefix: true,
                    stripLeadingZeroes: true,
                  }}
                />
              </Form.Item>

              <Form.Item
                name="mortgagePayment"
                label="Payment"
                onChange={handleMonthlyPaymentAmount}
                rules={[
                  {
                    required: true,
                    message: '*Required',
                  },
                ]}
              >
                <Cleave
                  className="ant-input"
                  placeholder="$"
                  options={{
                    numeral: true,
                    numeralIntegerScale: 7,
                    numeralPositiveOnly: true,
                    //prefix: '$',
                    signBeforePrefix: true,
                    stripLeadingZeroes: true,
                  }}
                />
              </Form.Item>

              <div className="homeValue">
                <Heading
                  content={'Is this the primary mortgage on this property?'}
                  fontWeight="bold"
                  {...title}
                />
                <div className="containerClassReasons">
                  <li>
                    <span>
                      <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                        <input
                          type="radio"
                          value="true"
                          name="radio5"
                          id="radio1"
                          style={{ visibility: 'hidden' }}
                          onChange={handleIsPrimaryMortgage}
                        />
                        <span>Yes</span>
                      </label>
                    </span>
                  </li>
                  <p></p>
                  <li>
                    <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        value="false"
                        name="radio5"
                        id="radio2"
                        style={{ visibility: 'hidden' }}
                        onChange={handleIsPrimaryMortgage}
                      />
                      <span>No</span>
                    </label>
                  </li>
                  <p></p>
                </div>
              </div>

              <div className="homeValue">
                <Heading
                  content={
                    'Do you pay taxes and insurance as part of your monthly payment?'
                  }
                  fontWeight="bold"
                  {...title}
                />
                <div className="containerClassReasons">
                  <li>
                    <span>
                      <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                        <input
                          type="radio"
                          value="true"
                          name="radio6"
                          id="radio1"
                          style={{ visibility: 'hidden' }}
                          onChange={
                            handleLiabilityPaymentIncludesTaxesInsuranceIndicator
                          }
                        />
                        <span>Yes</span>
                      </label>
                    </span>
                  </li>
                  <p></p>
                  <li>
                    <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        value="false"
                        name="radio6"
                        id="radio2"
                        style={{ visibility: 'hidden' }}
                        onChange={
                          handleLiabilityPaymentIncludesTaxesInsuranceIndicator
                        }
                      />
                      <span>No</span>
                    </label>
                  </li>
                  <p></p>
                </div>
              </div>

              {isPrimaryMortgage == 'false' && (
                <Heading
                  content={'Please add the additional mortgage information'}
                  fontWeight="bold"
                  {...title}
                />
              )}

              {isPrimaryMortgage == 'false' && (
                <Form.Item
                  name="mortgageCompany2"
                  label="Company you write the check to"
                  onChange={handleLiablilityHolder}
                  /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                >
                  <Input />
                </Form.Item>
              )}

              {isPrimaryMortgage == 'false' && (
                <Form.Item
                  name="mortgageBalance2"
                  label="Balance"
                  onChange={handleUnpaidLiabilityBalanceAmount}
                  rules={[
                    {
                      required: true,
                      message: '*Required',
                    },
                  ]}
                >
                  <Cleave
                    className="ant-input"
                    placeholder="$"
                    options={{
                      numeral: true,
                      numeralIntegerScale: 7,
                      numeralPositiveOnly: true,
                      //prefix: '$',
                      signBeforePrefix: true,
                      stripLeadingZeroes: true,
                    }}
                  />
                </Form.Item>
              )}

              {isPrimaryMortgage == 'false' && (
                <Form.Item
                  name="other2"
                  label="Payment"
                  onChange={handleMonthlyPaymentAmount}
                  rules={[
                    {
                      required: true,
                      message: '*Required',
                    },
                  ]}
                >
                  <Cleave
                    className="ant-input"
                    placeholder="$"
                    options={{
                      numeral: true,
                      numeralIntegerScale: 7,
                      numeralPositiveOnly: true,
                      //prefix: '$',
                      signBeforePrefix: true,
                      stripLeadingZeroes: true,
                    }}
                  />
                </Form.Item>
              )}

              {isPrimaryMortgage == 'false' && (
                <div className="homeValue">
                  <Heading
                    content={'Is this the primary mortgage on this property?'}
                    fontWeight="bold"
                    {...title}
                  />
                  <div className="containerClassReasons">
                    <li>
                      <span>
                        <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                          <input
                            type="radio"
                            value="true"
                            name="radio7"
                            id="radio1"
                            style={{ visibility: 'hidden' }}
                            //onChange={}
                          />
                          <span>Yes</span>
                        </label>
                      </span>
                    </li>
                    <p></p>
                    <li>
                      <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                        <input
                          type="radio"
                          value="false"
                          name="radio7"
                          id="radio2"
                          style={{ visibility: 'hidden' }}
                          //onChange={}
                        />
                        <span>No</span>
                      </label>
                    </li>
                    <p></p>
                  </div>
                </div>
              )}

              <Form.Item {...tailFormItemLayout}>
                <p></p>
                <div className="containerClassBuyProcess">
                  <Button
                    type="primary"
                    title="Next"
                    htmlType="submit"
                    {...button}
                    onClick={onFinish}
                    disabled={
                      !liabilityHolder ||
                      !monthlyPaymentAmount ||
                      !isPrimaryMortgage ||
                      !liabilityUnpaidBalanceAmount ||
                      !liabilityPaymentIncludesTaxesInsuranceIndicator
                    }
                  ></Button>
                </div>
              </Form.Item>
            </Form>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

RefiMakingPaymentsComponent.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

RefiMakingPaymentsComponent.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${20}`,
    fontWeight: '600',
    //borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${10}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '42px', '42px'],
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  label: {
    fontSize: '28px',
  },

  description: {
    content: 'This means your assets and income will be counted together.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    /* textAlign: ['center', 'center'], */
  },
};

const mapStateToProps = (state) => ({
  currentUser: state.root.currentUser?.user,
  userToken: state.root.currentUser?.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (currentUser, userToken) =>
      dispatch(updateUserInfo(currentUser, userToken)),
  };
};

const RefiMakingPaymentsComponentRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefiMakingPaymentsComponent);

export default RefiMakingPaymentsComponentRedux;
